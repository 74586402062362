<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import i18n from "@/i18n";
import { ROOM_MODULE } from '@/state/modules/meetingRoom'
import { meetingRoomMethods } from "@/state/helpers";
import { sessionHelper, ddlHelper } from "@/utils";
import { SESSION, SHARED } from "@/constants";
import { mapState } from "vuex";

/**
 * Shops Component
 */
export default {
    page: {
        title: i18n.t("label.listingX", [i18n.t('module.meetingRoom')]),
    },
    components: {
        Layout,
        PageHeader
    },
    created() {
        this.initSearchCriteria();
    },
    computed: {
        ...mapState(ROOM_MODULE, ['status']),
        tableRows() {
            return this.$store.state.meetingRoom.list.data;
        },
    },
    methods: {
        ...meetingRoomMethods,
        initSearchCriteria() {
            let isDefault = true;

            if (sessionHelper.hasSearchCriteria(SESSION.MEETING_ROOM_SEARCH)) {
                isDefault = false;
                this.searchCriteria = sessionHelper.retrieveSearch(SESSION.MEETING_ROOM_SEARCH);
            }

            if (isDefault) {
                this.searchCriteria = {
                    pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
                    pageSize: SHARED.DEFAULT_PAGE_SIZE,
                    isActive: this.statusDdl[0].value,
                    searchValue: "",
                    searchArray: [],
                };
            }
            this.getMeetingRoomList(this.searchCriteria);
        },

        getMeetingRoomList(filter) {
            this.getAllRooms({ filter });
            this.searchCriteria = filter;
            sessionHelper.storeSearch(SESSION.MEETING_ROOM_SEARCH, filter);
        },

        searchListing() {
            this.searchCriteria.pageNumber = SHARED.DEFAULT_PAGE_NUMBER;

            this.searchCriteria.searchArray = [];
            this.searchCriteria.searchArray.push(this.filter);
            this.getMeetingRoomList(this.searchCriteria);
        },

        toDetailsPage(id) {
            let filter = sessionHelper.retrieveSearch(SESSION.MEETING_ROOM_SEARCH)
            filter.selectedRecordId = id
            sessionHelper.storeSearch(SESSION.MEETING_ROOM_SEARCH, filter)

            this.$router.push({
                name: 'Calendar'
            })
        }
    },

    data() {
        return {
            title: i18n.t("label.listingX", [i18n.t('module.meetingRoom')]),
            statusDdl: ddlHelper.getActivationStatus(true),
            items: [
                {
                    text: i18n.t("module.dashboard"),
                    href: "/",
                },
            ],
        };
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-xl-3 col-sm-6" v-for="(data) in tableRows" :key="data.id" @click="toDetailsPage(data.id)">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center">
                            <img :src="`${data.image}`" alt class="avatar-lg" style="margin-bottom: 25px;" />
                            <div class="media-body">
                                <h5 class="text-truncate">
                                    <a href="#" class="text-dark" style="text-overflow:ellipsis; overflow:hidden; display: -webkit-box !important; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: normal;">
                                        {{ data.name }}
                                    </a>
                                </h5>
                                <p class="text-muted">
                                    <i class="mdi mdi-account mr-1"></i> {{ data.size }}
                                </p>
                                <b-button variant="primary" @click="closeModal">Book Now</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </Layout>
</template>